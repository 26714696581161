import { useAuthMutations } from '@api'
import { setCompanyIds } from '@api/resources/auth/tools'
import Button from '@components/buttons/Button'
import AuthInput from '@components/inputs/AuthInput'
import Text from '@components/typography/Text'
import Title from '@components/typography/Title'
import { zodResolver } from '@hookform/resolvers/zod'
import SignInImage from '@public/images/signin.webp'
import Via3LSignInImage from '@public/images/via3l-signin.png'
import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const schema = z.object({
  login: z.string().min(1),
  password: z.string().min(1),
})

type Schema = z.infer<typeof schema>

const LoginPage = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const searchParams = useSearchParams()
  const theme = searchParams.get('theme')

  const { register, handleSubmit, watch } = useForm<Schema>({
    resolver: zodResolver(schema),
  })

  const authMutations = useAuthMutations()

  const password = watch('password')
  const login = watch('login')

  const onSubmit = handleSubmit(data => {
    authMutations.login.mutate(data, {
      onSuccess: async ({ companyExternalId, companyId }) => {
        setCompanyIds(companyExternalId, companyId)
        router.push('/')
      },
    })
  })

  const error = authMutations.login.error as {
    response: {
      data: {
        message: string
      }
    }
  }

  const getTitle = (theme: string | null) => {
    if (theme === 'v1a3131') {
      return t('pages.login_via3l.title')
    }
    return t('pages.login.title')
  }

  return (
    <main className="flex relative items-center min-h-dvh bg-white">
      <div className="z-10 max-w-[1440px] mx-auto w-full">
        <form
          onSubmit={onSubmit}
          className="px-4 md:max-w-[420px] border-0 md:border lg:border-0 border-main-300 md:ml-[10%] bg-white z-10 p-5 rounded-lg"
        >
          <div className="flex flex-col gap-4 mb-11">
            <Title as="h1" className="text-primary">
              {getTitle(theme).toUpperCase()}
            </Title>

            <Text size="default" as="p" className="text-text-500">
              {t('pages.login.description_1')}
              <Text as="span" size="subtitle-default">
                {getTitle(theme)}
              </Text>
              {t('pages.login.description_2')}
            </Text>
          </div>

          <div className="flex flex-col gap-5 w-full">
            <AuthInput
              type="text"
              isError={
                error?.response?.data?.message ===
                'User with this login was not found'
                  ? error?.response?.data?.message
                  : false
              }
              reset={authMutations.login.reset}
              label={t('pages.login.form.login')}
              register={register('login', { required: true })}
            />

            <AuthInput
              label={t('pages.login.form.password')}
              isError={
                error?.response?.data?.message === 'Incorrect password'
                  ? error?.response?.data?.message
                  : false
              }
              reset={authMutations.login.reset}
              register={register('password', { required: true })}
              type="password"
            />
          </div>

          <Button
            type="submit"
            disabled={
              !login ||
              !password ||
              authMutations.login.isLoading ||
              authMutations.login.isError
            }
            className="w-full mt-11"
            size="title"
            label={t('pages.login.form.sign_in')}
          />
        </form>
      </div>

      <Image
        src={theme === 'v1a3131' ? Via3LSignInImage : SignInImage}
        alt="Signin Image"
        className="h-full hidden md:block object-top w-auto absolute top-0 bottom-0 right-0 z-0"
      />
    </main>
  )
}

export default LoginPage

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!)),
    },
  }
}
