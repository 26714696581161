import Text from '@components/typography/Text'
import HiddenIcon from '@public/icons/login/hidden.svg'
import VisibleIcon from '@public/icons/login/visible.svg'
import { classnames } from '@tools/common'
import { useState } from 'react'
import { UseFormRegister } from 'react-hook-form'

interface AuthInputProps {
  register: ReturnType<UseFormRegister<any>>
  label: string
  type: 'text' | 'password'
  isError?: boolean | string
  reset?: () => void
}

const AuthInput = ({
  register,
  label,
  type,
  isError,
  reset,
}: AuthInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className="relative">
      <div
        className={classnames(
          'border-2 rounded-xl px-3 pb-1 border-main-900 h-full w-full',
          {
            'border-primary': isFocused,
            'border-danger-500': isError,
          },
        )}
      >
        <div className="pt-1.5">
          <Text size="caption-default" as="span" className="text-main-900">
            {label}
          </Text>
        </div>

        <div className="flex justify-between items-center">
          <input
            {...register}
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            className="border-0 px-0 w-full text-body-extra text-primary focus:outline-none ring-0 focus:ring-0"
            onFocus={() => {
              setIsFocused(true)
              reset && reset()
            }}
            onBlur={() => setIsFocused(false)}
          />
          {type === 'password' ? (
            <button
              type="button"
              className="p-2"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <HiddenIcon /> : <VisibleIcon />}
            </button>
          ) : (
            <div className="w-8" />
          )}
        </div>
      </div>

      {isError && (
        <Text
          size="subtitle-small"
          as="span"
          className="absolute text-danger-500 left-3 -bottom-4"
        >
          {isError}
        </Text>
      )}
    </div>
  )
}

export default AuthInput
